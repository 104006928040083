html {
  overscroll-behavior-x: contain;
}

body {
  height: 100vh;
  width: 100vw;
  overscroll-behavior-y: contain;
  overscroll-behavior-x: contain;
  margin: 0;
  -webkit-user-select: none; /* Disable selection in Webkit browsers */
  -moz-user-select: none; /* Disable selection in Mozilla Firefox */
  -ms-user-select: none; /* Disable selection in Microsoft Edge */
  user-select: none; /* Disable selection in standard browsers */
}

.background1 {
  background-image: url("./assets/images/alap-01-edited.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: 1;
}

.background2 {
  background-image: url("./assets/images/alap-02.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  animation: fadeEffect 20s infinite;
  position: absolute;
  z-index: 2;
}

@keyframes fadeEffect {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.soundicon {
  width: 50px;
  height: 50px;
}

.sound-controls {
  position: absolute;
  top: 440px;
  left: 100px;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.timeline-outer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.timeline {
  width: 100%;
  -webkit-appearance: none;
}

.timeline::-webkit-slider-runnable-track {
  height: 2px;
  background: #694e38;
}

.timeline::-webkit-slider-thumb {
  -webkit-appearance: none;
  background-color: #694e38;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin-top: -4px;
  outline: 1px solid #694e38;
  border: 1px solid wheat;
}

input[type="range"]::-moz-range-thumb {
  background-color: #694e38;
  width: 10px;
  height: 10px;
}

@media screen and (min-width: 768px) {
  .background1,
  .background2 {
    background-size: contain;
  }
  .sound-controls {
    top: 595px;
    left: 752px;
    width: 20%;
  }
}
